<template>
  <div class="main-content">
    <div class="main-title">相关网站列表</div>
    <div class="main-btn">
      <span class="btn-add" @click="add">添加</span>
    </div>
    <table>
      <tr>
        <th width="100">ID</th>
        <th class="td-left" >名称</th>
        <th class="td-left" >链接地址</th>
        <th width="50"></th>
      </tr>
      <template v-for="(item) in list">
        <tr class="row" :key="item.id" @click="rowSelect(item.id)">
          <td class="td-center">{{item.id}}</td>
          <td>{{item.title}}</td>
          <td>{{item.link_url}}</td>
          <td class="td-center">
            <img class="more" src="@/assets/list_icon_more.png">
            <div class="more-box" v-if="selectRow == item.id">
              <div class="op-row" @click="editRow(item.id)">编辑</div>
              <div class="op-row" @click="delRow(item.id)">删除</div>
            </div>
          </td>
        </tr>
      </template>
    </table>
    <div class="paging-box">
      <paging :current-page="page" :total="total" :page-size="9" @currentChange="pageChange"/>
    </div>
  </div>
</template>

<script>
import {getLinkList,editLink} from "@/request/api";
import paging from "@/components/paging";
import {Warning} from "@/plugins/warning";

export default {
  name: "link_web_list",
  components:{
    paging
  },
  data() {
    return {
      list:[],
      page: 0,
      total: 0,
      totalPage: 0,
      selectRow: 0,
      stateKV:{
        1:"启用",
        9:"禁用"
      },
      stateStyleKV:{
        1:"green",
        9:"red"
      },
      stateBtnKV:{
        1:"禁用",
        9:"启用"
      }
    }
  },
  computed: {

  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {
    getList(e){
      const curPage = e || this.page
      const requestData = {
        page: curPage,
        type:6
      };
      getLinkList(requestData).then((res)=>{
        this.list = res.data.list;
        this.total = res.data.paging.recordCount
        this.totalPage = res.data.paging.pageCount
        this.page = curPage
      })
    },
    pageChange(e){
      this.getList(e)
    },
    rowSelect(sel){
      if(sel == this.selectRow){
        this.selectRow = 0;
      }else{
        this.selectRow = sel;
      }
    },
    delRow(sel){
      this.selectRow = 0;
      const requestData = {
        id: sel,
        state:9
      };

      this.$confirm("确定要删除吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        editLink(requestData).then(()=>{
          Warning.success("操作成功");
          this.list.forEach((item)=>{
            if(item.id == sel){
              this.list.splice(this.list.indexOf(item), 1)
            }
          })
        })
      })
    },
    editRow(sel){
      this.selectRow = 0;
      this.$router.push({name:"link_web_edit",query:{id:sel}}, ()=>{}, ()=>{})
    },
    add(){
      this.$router.push({name:"link_web_edit"})
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .main-btn{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-right: 30px;
    margin-top: 30px;
    .btn-add{
      display: block;
      width: 100px;
      line-height: 48px;
      font-size: 21px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 25px;
      background-color: #ba0704;
    }
  }
  & > table{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    & > tr{
      & > th{
        font-size: 18px;
        font-family: Jost-Medium, Jost;
        font-weight: 500;
        color: #000;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .td-left{
      text-align: left;
    }
    .td-center{
      text-align: center;
    }
    .row{
      background: #f8f6f0;
      border-radius: 15px;
      box-shadow: 0 0 0 5px #f8f6f0;
      height: 50px;
      font-size: 17px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #2E2E2E;
      position: relative;
      cursor: pointer;
      td{
        vertical-align:middle;
        padding-left: 15px;
        padding-right: 15px;
      }
      .thumb{
        width: 100px;
        height: 100px;
        object-fit: cover;
        display:table-cell;
        vertical-align:middle;
      }
      .more{
        width: 25px;
        height: 25px;
        margin-top: 15px;
      }
      .more-box{
        width: 45px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
        position: absolute;
        top: 20px;
        right: 35px;
        z-index: 999;
        padding-top: 5px;
        padding-bottom: 5px;
        .op-row{
          height: 25px;
          line-height: 25px;
          width: 45px;
          font-size: 12px;
          font-weight: 300;
          color: #2E2E2E;
          cursor: pointer;
        }
      }
      .red{
        color: red;
      }
      .green{
        color: green;
      }
    }
  }

  .paging-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0 130px;
  }
}
</style>
